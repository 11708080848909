/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '/src/assets/styles/fonts.scss';
@import 'swiper/css';

body {
  background-color: #0C1420;
  color: white;
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .rotate-x-180 {
    transform: rotateX(180deg);
  }

  .preserve-3d {
    transform-style: preserve-3d;
  }

  .perspective {
    perspective: 1000px;
  }

  .backface-hidden {
    backface-visibility: hidden;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

:root.ios {
  --ion-safe-area-bottom: 1rem;


  ion-refresher.refresher-native .refresher-pulling-icon, ion-refresher.refresher-native .refresher-refreshing-icon {
    border: unset;
    background: unset;
  }
}
:root {
  --ion-text-color: #FFF;
  --ion-color-primary: #4CE364;
  --ion-background-color: #0C1420;
}

ion-refresher.refresher-native .refresher-pulling-icon, ion-refresher.refresher-native .refresher-refreshing-icon {
  border: 1px solid #25354a;
  background: #111a27;
}
